/* import __COLOCATED_TEMPLATE__ from './details.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { AmountField } from '@repo/design-system-kit';
import dayjs from 'dayjs';

import { DATE_PICKER_FIELD_FORMAT } from 'qonto/constants/dates';
import { ErrorInfo } from 'qonto/utils/error-info';

export default class FlowsDirectDebitCollectionsSubscriptionDetailsComponent extends Component {
  @service directDebitCollectionsManager;
  @service localeManager;
  @service organizationManager;
  @service store;
  @service sentry;
  @service segment;

  @tracked isValidationEnabled = false;
  @tracked mandates = [];

  minCollectionDate;
  amountField = AmountField;

  constructor() {
    super(...arguments);

    let tomorrow = new Date().setDate(new Date().getDate() + 1);
    this.minCollectionDate = dayjs(tomorrow).format(DATE_PICKER_FIELD_FORMAT);

    this.directDebitCollectionsManager
      .loadMandates(this.subscription.client.id)
      .then(mandates => {
        if (mandates.length) {
          this.mandates = mandates;
        }

        if (this.args.context.useExistingMandate) {
          this.subscription.mandate = this.mandates[0];
        }
      })
      .catch(error => {
        if (ErrorInfo.for(error).shouldSendToSentry) {
          this.sentry.captureException(error);
        }
      });
  }

  get subscription() {
    return this.args.context.directDebitSubscription;
  }

  get bankAccounts() {
    return this.organizationManager.organization.bankAccounts;
  }

  get isInvalidAmountField() {
    if (this.isValidationEnabled) {
      return Boolean(this.subscription.validations.attrs.amount.value.message);
    }

    return false;
  }

  @action
  onAmountChange(amountValue) {
    this.subscription.setProperties({
      amount: { ...this.subscription.amount, value: amountValue },
    });
  }

  @action
  onUpdateFrequency({ scheduleType, scheduleCustomInterval, scheduleCustomUnit }) {
    this.subscription.setProperties({ scheduleType, scheduleCustomInterval, scheduleCustomUnit });
  }

  @action
  onUpdateDuration({ scheduleTotalCollectionCount, isCustomDuration }) {
    this.subscription.setProperties({ scheduleTotalCollectionCount, isCustomDuration });
  }

  @action
  onSelectMandate(mandate) {
    this.subscription.mandate = mandate;
    this.args.context.useExistingMandate = Boolean(mandate);
  }

  @action
  onCTAClick() {
    this.isValidationEnabled = true;

    this.subscription.validate({}, false);

    if (this.subscription.validations.isValid) {
      this.args.transitionToNext();

      let { isOneOff, isRecurring, scheduleType, scheduleTotalCollectionCount } = this.subscription;

      this.segment.track('incoming-direct-debit-flow_collection-details_submitted', {
        collection_type: isOneOff ? 'one_off' : 'recurring',
        ...(isRecurring && { frequency: scheduleType }),
        ...(isRecurring && {
          duration: scheduleTotalCollectionCount ? 'number_payments' : 'no_limit',
        }),
      });
    }
  }
}
