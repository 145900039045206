export default {
  "wrapper": "cRb",
  "confirm": "cRy",
  "plan-info": "cRU",
  "plan-info-left": "cRj",
  "info-title": "cRW body-1",
  "initial-trial-content": "cRu",
  "extra-fees-item": "cQS body-2",
  "extra-fees-container": "cQc",
  "bottom-wrapper": "cQq",
  "total-price-container": "cQZ",
  "extra-fees-element": "cQR body-1",
  "border": "cQQ",
  "subtitle": "cQe body-2",
  "group-item": "cQB",
  "amount": "cQE",
  "subscription-billing-summary": "cQd",
  "summary": "cQI",
  "summary-list": "cQt",
  "disclaimer": "cQM",
  "disclaimer-revamp": "cQP",
  "error": "cQl",
  "error-link": "cQX",
  "warning-wrapper": "cQC",
  "warning-label": "cQk body-1"
};
