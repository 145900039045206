export default {
  "container": "cBN",
  "content": "cBv",
  "sticky": "cBo",
  "divider": "cBn",
  "tooltip": "cBi",
  "detail": "cBA",
  "detail-value": "cBY",
  "color-secondary": "cBh",
  "beneficiary-information": "cBf",
  "beneficiary-source": "cBK",
  "currency": "cBG",
  "break-word": "cBr",
  "disclaimers": "cBb",
  "sticky-panel": "cBy"
};
