export default {
  "details": "cqr",
  "disclaimer": "cqb",
  "data": "cqy",
  "confirm": "cqU",
  "form": "cqj",
  "textarea": "cqW",
  "preview": "cqu",
  "form-title": "cZS title1",
  "form-subtitle": "cZc title2",
  "divider": "cZq",
  "submit": "cZZ",
  "doc": "cZR",
  "amount-input-error": "cZQ"
};
