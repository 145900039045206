export default {
  "animated": "GN",
  "container": "Gv",
  "cards-selectors": "Go",
  "card-selector": "Gn",
  "selector": "Gi",
  "header": "GA",
  "footer": "GY",
  "skip-cta": "Gh",
  "pricing-caption": "Gf",
  "show": "GK",
  "selectorFadeIn": "GG",
  "fadeIn": "Gr",
  "asset-one": "Gb",
  "onePlusAssetFadeIn": "Gy",
  "assetMoveUp": "GU",
  "asset-plus": "Gj",
  "asset-x": "GW",
  "xAssetFadein": "Gu",
  "feature": "rS",
  "highlighted": "rc"
};
