export default {
  "wrapper": "cIm",
  "close-button": "cIp",
  "content-wrapper": "cIx",
  "main": "cIw",
  "main-content": "cIN",
  "description": "cIv",
  "mt-26": "cIo",
  "mt-62": "cIn",
  "user-prompt": "cIi",
  "user-prompt-inappropriate-warning": "cIA",
  "logo-preview": "cIY",
  "static-loader": "cIh",
  "loading-state-illustration": "cIf",
  "sticky-panel": "cIK"
};
