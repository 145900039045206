/* import __COLOCATED_TEMPLATE__ from './form.hbs'; */
import { action, get } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { Disclaimer } from '@repo/design-system-kit';
import { alias } from 'macro-decorators';

import scrollIntoView from 'qonto/utils/scroll-into-view';

export default class F24FormComponent extends Component {
  disclaimerBlock = Disclaimer.Block;

  @service store;

  @alias('args.f24Order') f24Order;
  @alias('args.disabled') disabled;

  @action scrollIntoView({ element }) {
    // add 32px offset so looks nicer
    scrollIntoView(element, { offsetTop: 32 });
  }

  @action onUpdatePayerAgent(attribute, value) {
    let { payerAgent } = this.args.f24Order;
    payerAgent.set(attribute, value);
  }

  @action onUpdatePayer(attribute, value) {
    let { payer } = this.args.f24Order;
    payer.set(attribute, value);
  }

  @action onUpdateItem(item, attribute, value) {
    item.set(attribute, value);
  }

  @action addErarioItem() {
    let { erario } = this.args.f24Order.taxes;
    erario.taxList.push(this.store.createRecord('f24/erario-tax-item'));
  }

  @action addInailItem() {
    let { inailTaxList } = this.args.f24Order.taxes;
    inailTaxList.push(this.store.createRecord('f24/inail-tax-item'));
  }

  @action addInpsItem() {
    let { inpsTaxList } = this.args.f24Order.taxes;
    inpsTaxList.push(this.store.createRecord('f24/inps-tax-item'));
  }

  @action addRegioniItem() {
    let { regioniTaxList } = this.args.f24Order.taxes;
    regioniTaxList.push(this.store.createRecord('f24/regioni-tax-item'));
  }

  @action addImuItem() {
    let { imu } = this.args.f24Order.taxes;
    imu.taxList.push(this.store.createRecord('f24/imu-tax-item'));
  }

  @action addOthersItem() {
    let { taxList } = this.args.f24Order.taxes.others;
    taxList.push(this.store.createRecord('f24/others-tax-item'));
  }

  @action removeListItem(listName, index) {
    get(this.args.f24Order, listName).splice(index, 1);
  }

  nonZeroTaxListItem({ compensationAmount, taxAmount }) {
    return Boolean(compensationAmount) || Boolean(taxAmount);
  }

  get shouldHideErario() {
    return (
      this.disabled &&
      !this.f24Order.taxes.erario?.taxList.some(this.nonZeroTaxListItem) &&
      !this.f24Order.taxes.erario?.deedCode &&
      !this.f24Order.taxes.erario?.officeCode
    );
  }

  get shouldHideInps() {
    return this.disabled && !this.f24Order.taxes.inpsTaxList?.some(this.nonZeroTaxListItem);
  }

  get shouldHideRegioni() {
    return this.disabled && !this.f24Order.taxes.regioniTaxList?.some(this.nonZeroTaxListItem);
  }

  get shouldHideImu() {
    return (
      this.disabled &&
      !this.f24Order.taxes.imu?.taxList.some(this.nonZeroTaxListItem) &&
      !this.f24Order.taxes.imu?.operationCode &&
      !this.f24Order.taxes.imu?.deductionAmount
    );
  }

  get shouldHideInail() {
    return (
      this.disabled &&
      !this.f24Order.taxes.inailTaxList?.some(this.nonZeroTaxListItem) &&
      !this.f24Order.taxes.others?.taxList.some(this.nonZeroTaxListItem) &&
      !this.f24Order.taxes.others?.institutionCode
    );
  }

  get hasFinalChecksFailedError() {
    return this.f24Order.errors.errorsFor('/').some(e => e.message === 'generic_error');
  }

  get hasErrorsContribuente() {
    return this.hasErrorsPayer || this.hasErrorsPayerAgent;
  }

  get hasErrorsPayer() {
    return (
      this.f24Order.payer?.errors.length ||
      this.f24Order.payer?.address.errors.length ||
      this.f24Order.errors.get('payer')?.length
    );
  }

  get hasErrorsPayerAgent() {
    return (
      this.f24Order.payerAgent?.errors.length || this.f24Order.errors.get('payerAgent')?.length
    );
  }

  get hasErrorsErario() {
    return (
      this.f24Order.taxes.erario?.errors.length ||
      this.f24Order.taxes.erario?.taxList.some(t => t.errors.length) ||
      this.f24Order.taxes.errors.get('erario')?.length
    );
  }

  get hasErrorsInps() {
    return (
      this.f24Order.taxes.inpsTaxList.some(t => t.errors.length) ||
      this.f24Order.errors.get('taxes/inps/taxList')?.length
    );
  }

  get hasErrorsRegioni() {
    return (
      this.f24Order.taxes.regioniTaxList.some(t => t.errors.length) ||
      this.f24Order.errors.get('taxes/regioni/taxList')?.length
    );
  }

  get hasErrorsImu() {
    return (
      this.f24Order.taxes.imu?.errors.length ||
      this.f24Order.taxes.imu?.taxList.some(t => t.errors.length) ||
      this.f24Order.taxes.errors.get('imu')?.length
    );
  }

  get hasErrorsInail() {
    return (
      this.f24Order.taxes.inailTaxList.some(t => t.errors.length) ||
      this.f24Order.errors.get('taxes/otherInstitutions/inail/taxList')?.length
    );
  }

  get hasErrorsOthers() {
    return (
      this.f24Order.taxes.others?.errors.length ||
      this.f24Order.taxes.others?.taxList.some(t => t.errors.length) ||
      this.f24Order.errors.get('taxes/otherInstitutions/others')?.length
    );
  }
}
