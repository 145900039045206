export default {
  "wrapper": "cST",
  "promotion-title": "cSz",
  "comparison-table": "cSa",
  "table-head": "cSH",
  "title-wrapper": "cSO",
  "illustration-wrapper": "cSm",
  "illustration": "cSp",
  "role-wrapper": "cSx",
  "role-disabled": "cSw",
  "role-name": "cSN",
  "role-access": "cSv",
  "disabled-caption": "cSo",
  "table-head-sticky": "cSn",
  "show-border": "cSi",
  "group-row": "cSA",
  "table-body": "cSY",
  "group": "cSh",
  "table-body-row": "cSf",
  "feature-description": "cSK body-2",
  "feature": "cSG body-2",
  "group-spacer": "cSr"
};
