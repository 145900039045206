export default {
  "integration-item": "cMK",
  "icon-container": "cMG",
  "content": "cMr",
  "title-container": "cMb",
  "new-badge": "cMy",
  "title": "cMU",
  "description": "cMj",
  "no-attributes": "cMW",
  "connect-cta": "cMu"
};
