export default {
  "step-container": "Wl",
  "content": "WX",
  "section": "WC",
  "section-content": "Wk",
  "section-content-text": "WJ",
  "section-illustration": "Ws",
  "close-form-cta": "WF",
  "bank-account-select": "Wg"
};
