export default {
  "checks": "Us",
  "wrapper": "UF",
  "form": "Ug",
  "title": "UD",
  "wrapper-body": "UV",
  "aside": "UL",
  "steps-container": "UT",
  "step": "Uz",
  "container-dot": "Ua",
  "text": "UH",
  "text-title": "UO",
  "text-desc": "Um",
  "start-button": "Up"
};
