export default {
  "page-wrapper": "Wp",
  "container": "Wx",
  "content": "Ww",
  "showElement": "WN",
  "illustration": "Wv",
  "fadeIn": "Wo",
  "scaleDown": "Wn",
  "error-content": "Wi",
  "error-illustration": "WA"
};
