export default {
  "wrapper": "cXP",
  "mobile-mode": "cXl",
  "logo": "cXX",
  "address-form": "cXC",
  "button-container": "cXk",
  "address-form-header": "cXJ",
  "address-form-content": "cXs",
  "address-form-address-search": "cXF",
  "ember-basic-dropdown": "cXg",
  "address-form-footer": "cXD",
  "submit-cta": "cXV",
  "form": "cXL",
  "city-section": "cXT",
  "title": "cXz",
  "mobile-title": "cXa"
};
