export default {
  "bucket-item": "cMH",
  "button": "cMO",
  "content": "cMm",
  "bucket-icon": "cMp",
  "bucket-title-container": "cMx",
  "bucket-new-badge": "cMw",
  "bucket-title": "cMN",
  "arrow-icon": "cMv"
};
