/* import __COLOCATED_TEMPLATE__ from './review.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import { htmlSafe } from '@ember/template';
import Component from '@glimmer/component';

import {
  CARD_DESIGNS,
  CARD_LEVELS,
  CARD_PRINT_TYPES,
  FORMAT_PRICE_OPTIONS,
  ORIGIN,
  X_CARD_DESIGNS,
} from 'qonto/constants/cards';
import { importAsset } from 'qonto/helpers/import-asset';
import { CardReviewPanel } from 'qonto/react/components/card/card-review-panel';

export default class FlowsCardsReview extends Component {
  @service abilities;
  @service intl;
  @service organizationManager;
  @service segment;

  cardlevels = CARD_LEVELS;
  cardReviewPanel = CardReviewPanel;

  get cardPanelInfo() {
    return (
      this.args.cardPanelInfo ?? {
        icon: `card-${this.args.context.card.design}`,
        displayNameKey: this.args.context.card.design,
        displayTypeKey: this.args.context.card.cardLevel,
        displayTypeOfPrint: this.args.context.card.typeOfPrint,
      }
    );
  }

  get legalCountry() {
    return this.organizationManager.organization.legalCountry;
  }

  get estimatedCosts() {
    let { estimates, card } = this.args.context;
    return estimates[card.cardLevel] || {};
  }

  get hasCardDiscount() {
    return (
      this.estimatedCosts.discount_finish_date &&
      this.estimatedCosts.discounted_monthly_cost !== this.estimatedCosts.monthly_cost
    );
  }

  get deliveryCardCost() {
    return this.estimatedCosts.delivery_amount_cents / 100;
  }

  get monthlyCostSublabel() {
    if (this.estimatedCosts.amount > 0) {
      return this.intl.t('cards.steps.review.card-cost.sublabel', {
        computed_amount: this._formatPrice(this.estimatedCosts.amount || 0),
        legalCountry: this.legalCountry,
      });
    }

    return this.intl.t('cards.steps.review.card-cost.sublabel-free');
  }

  get monthlyCost() {
    if (this.estimatedCosts.amount > 0 || this.estimatedCosts.discount_finish_date) {
      return htmlSafe(
        `${this._formatPrice(this.estimatedCosts.monthly_cost || 0)} <span>${this.intl.t(
          'cards.steps.review.card-cost.pricing-period',
          {
            legalCountry: this.legalCountry,
          }
        )}</span>`
      );
    }

    return this.intl.t('cards.steps.review.card-cost.free');
  }

  get deliveryCostSublabel() {
    return this.deliveryCardCost > 0
      ? this.intl.t('cards.steps.review.printing-delivery-cost.sublabel-extra')
      : this.intl.t('cards.steps.review.printing-delivery-cost.sublabel-included');
  }

  get deliveryCost() {
    return this.deliveryCardCost > 0
      ? this._formatPrice(this.deliveryCardCost || 0)
      : this.intl.t('cards.steps.review.printing-delivery-cost.free');
  }

  get shouldShowSpecificDays() {
    return this.args.context.card.activeDaysOption;
  }

  get shouldShowSpecificCategories() {
    return (
      this.abilities.can('view restrictive categories card') && this.args.context.card.categoryTags
    );
  }

  get shouldShowMontlyCostTooltip() {
    return !this.estimatedCosts.discount_finish_date && this.estimatedCosts.amount > 0;
  }

  get displayCardType() {
    switch (this.cardPanelInfo.displayTypeKey) {
      case CARD_LEVELS.STANDARD:
      case CARD_LEVELS.PLUS: {
        return this.cardPanelInfo.displayTypeOfPrint === CARD_PRINT_TYPES.EMBOSSED
          ? this.intl.t('cards.steps.review.print-type.embossed')
          : this.intl.t('cards.steps.review.print-type.printed');
      }
      case CARD_LEVELS.METAL: {
        return this.intl.t('cards.steps.review.print-type.engraved');
      }
      case CARD_LEVELS.VIRTUAL:
      case CARD_LEVELS.ADVERTISING:
        return this.intl.t('cards.steps.review.card-type.virtual-permanent');
      case CARD_LEVELS.FLASH:
        return this.intl.t('cards.steps.review.card-type.virtual-disposable');
    }
  }

  get displayedCardName() {
    return this.args.context.card.displayedCardName(this.cardPanelInfo.displayTypeKey);
  }

  get cardColor() {
    let { displayNameKey, displayTypeKey } = this.cardPanelInfo;

    if (CARD_LEVELS.PLUS === displayTypeKey) {
      switch (displayNameKey) {
        case CARD_DESIGNS.PLUS_RECYCLED_PLASTIC_LILAC_2023:
          return this.intl.t('cards.colors.plus.light-purple');
        case CARD_DESIGNS.PLUS_RECYCLED_PLASTIC_BLACK_2023:
          return this.intl.t('cards.colors.plus.black');
        default:
          return this.intl.t('cards.colors.plus.silver');
      }
    }

    if (CARD_LEVELS.METAL === displayTypeKey) {
      switch (displayNameKey) {
        case X_CARD_DESIGNS.METAL_MINERAL_GRAY_2024:
          return this.intl.t('cards.colors.metal.mineral-gray');
        case X_CARD_DESIGNS.METAL_SAND_GOLD_2024:
          return this.intl.t('cards.colors.metal.sand-gold');
        case X_CARD_DESIGNS.METAL_GRAPHITE_BLACK_2019:
          return this.intl.t('cards.colors.metal.graphite-black');
        default:
          return null;
      }
    }

    return null;
  }

  get cardImageUri() {
    return importAsset([`/illustrations/cards/${this.cardPanelInfo.icon}.svg`]);
  }

  get isOriginMemberInvitee() {
    return this.args.context.origin === ORIGIN.MEMBER_INVITATION;
  }

  get shouldDisplayEditCardSettings() {
    return this.args.context.displayEditCardSettings;
  }

  @action
  handleEditCardSettingsClick() {
    let { card, flowTrackingOrigin } = this.args.context;
    this.segment.track('cards_edit-settings_clicked', {
      origin: flowTrackingOrigin,
      card_type: card.cardLevel,
    });
    this.args.transitionToNext();
  }

  _formatPrice(price) {
    return this.intl.formatNumber(price, FORMAT_PRICE_OPTIONS);
  }
}
