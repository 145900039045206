export default {
  "container": "rh",
  "container-metal": "rf",
  "content": "rK",
  "button": "rG",
  "embossed-selector": "rr",
  "carousel-container": "rb",
  "carousel": "ry",
  "asset": "rU",
  "poster": "rj",
  "video-embossed": "rW",
  "video": "ru",
  "active": "bS",
  "embossed": "bc",
  "safari": "bq",
  "poster-open": "bZ"
};
