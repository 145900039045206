export default {
  "checks": "Uq",
  "content": "UZ",
  "form": "UR",
  "title": "UQ",
  "description": "Ue",
  "wrapper": "UB",
  "aside": "UE",
  "amount-field": "Ud"
};
