export default {
  "label-wrapper": "yi body-1",
  "avatar": "yA",
  "container": "yY",
  "transaction-info": "yh",
  "secondary": "yf",
  "amount-container": "yK",
  "amount": "yG",
  "disabled": "yr",
  "checkbox": "yb"
};
