export default {
  "journey": "cZX",
  "content": "cZC",
  "distance": "cZk",
  "google-link-container": "cZJ",
  "link-icon": "cZs",
  "continue-btn": "cZF",
  "origin": "cZg",
  "input-width": "cZD"
};
