export default {
  "selector": "rH",
  "metal-design": "rO",
  "content": "rm",
  "header": "rp",
  "big": "rx",
  "header-name": "rw",
  "subtitle": "rN",
  "button": "rv",
  "features": "ro",
  "feature": "rn",
  "highlighted": "ri"
};
