export default {
  "step-container": "ceN",
  "content": "cev",
  "beneficiary": "ceo",
  "beneficiary-name": "cen",
  "trusted": "cei",
  "amount-disclaimer": "ceA",
  "purpose-subtitle": "ceY",
  "purposes": "ceh",
  "purpose-error": "cef",
  "sticky-panel": "ceK"
};
