export default {
  "container": "cBV",
  "content": "cBL",
  "quote-information-item": "cBT",
  "divider": "cBz",
  "tooltip": "cBa",
  "amount": "cBH title-4",
  "align-left": "cBO",
  "sticky-panel": "cBm"
};
