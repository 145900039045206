export default {
  "container": "cIX",
  "container--collapsed": "cIC",
  "spin": "cIk",
  "cta-tooltip": "cIJ",
  "cta--collapsed": "cIs",
  "cta--expanded": "cIF",
  "collapsed-svg-icon": "cIg",
  "expanded-svg-icon": "cID",
  "generate-logo-cta": "cIV",
  "badge": "cIL",
  "scaleDown": "cIT",
  "fadeIn": "cIz",
  "textRiseButton": "cIa",
  "cta-description": "cIH",
  "textRiseDescription": "cIO"
};
