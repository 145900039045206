export default {
  "card": "rd",
  "asset": "rI",
  "container": "rt",
  "subtitle": "rM",
  "header": "rP",
  "header-name": "rl",
  "option-list": "rX",
  "feature": "rC",
  "highlighted": "rk"
};
