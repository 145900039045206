export default {
  "container": "Gk",
  "back-button": "GJ",
  "card-list": "Gs",
  "upsell-badge": "GF",
  "pricing": "Gg",
  "pricing-period": "GD",
  "ad-modal-button": "GV",
  "eligibility-disclaimer": "GL",
  "feature": "GT",
  "highlighted": "Gz"
};
