export default {
  "wrapper": "cRv",
  "wrapper-revamp": "cRo",
  "container": "cRn",
  "container-revamp": "cRi",
  "warning-label": "cRA",
  "subtitle-container": "cRY",
  "info-container": "cRh",
  "card": "cRf",
  "disclaimer": "cRK",
  "list": "cRG",
  "list-item": "cRr body-2"
};
