export default {
  "wrapper": "clX",
  "content": "clC",
  "back": "clk btn btn--tertiary",
  "heading": "clJ",
  "heading-content": "cls",
  "logo": "clF",
  "placeholder": "clg",
  "description": "clD body-2",
  "cta": "clV"
};
