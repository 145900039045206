export default {
  "container": "bd",
  "centered": "bI",
  "content": "bt",
  "spinner": "bM",
  "holder-selector": "bP",
  "holder-selector-error": "bl",
  "info-text": "bX",
  "error-text": "bC",
  "lottie-illustration": "bk",
  "info-text-1": "bJ",
  "info-text-2": "bs"
};
