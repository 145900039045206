export default {
  "upsell-cta": "cco",
  "discover-badge": "ccn",
  "role-selection-item": "cci",
  "unavailable": "ccA",
  "role-headline": "ccY",
  "illustration": "cch",
  "role-description": "ccf",
  "description-item": "ccK",
  "description-icon": "ccG",
  "description-text": "ccr",
  "current-role": "ccb"
};
