export default {
  "container": "cRd",
  "form-core": "cRI",
  "label": "cRt",
  "request-description": "cRM",
  "inline-inputs": "cRP",
  "placeholder-label": "cRl",
  "placeholder-input": "cRX",
  "placeholder-checkbox": "cRC"
};
