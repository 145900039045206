export default {
  "wrapper": "cdw input-field mb-4",
  "error": "cdN",
  "inactive": "cdv",
  "hidden-value": "cdo",
  "percentage-sign": "cdn",
  "black": "cdi",
  "input-field": "cdA",
  "disabled": "cdY"
};
