export default {
  "container": "cXx",
  "form": "cXw",
  "form-header": "cXN",
  "form-subtitle": "cXv",
  "form-input": "cXo",
  "left-input": "cXn",
  "right-input": "cXi",
  "label-fix": "cXA",
  "x-validated-input__header": "cXY",
  "form-dropzone": "cXh",
  "form-footer": "cXf",
  "submit-cta": "cXK",
  "checklist": "cXG",
  "about-you-form": "cXr",
  "name-inputs": "cXb",
  "birth-inputs": "cXy"
};
