export default {
  "guests-loading": "ctx",
  "header": "ctw",
  "body": "ctN",
  "members": "ctv",
  "title": "cto caption-bold",
  "placeholder-container": "ctn",
  "icon": "cti",
  "details": "ctA"
};
