export default {
  "card": "ctZ",
  "text-container": "ctR",
  "text-title": "ctQ title-3",
  "text-subtitle": "cte body-1",
  "text-cta": "ctB body-1",
  "text-cta-icon": "ctE",
  "image": "ctd",
  "image-illustration": "ctI"
};
