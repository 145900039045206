export default {
  "container": "clq",
  "logo": "clZ",
  "title": "clR title-4",
  "description": "clQ body-2",
  "metadata": "cle",
  "risks-container": "clB",
  "label": "clE caption",
  "risks": "cld",
  "tag": "clI tag"
};
