export default {
  "step-container": "ceu",
  "budget-tooltip": "cBS",
  "content": "cBc",
  "summary-description": "cBq",
  "detail": "cBZ",
  "separator": "cBR",
  "detail-value": "cBQ",
  "color-secondary": "cBe",
  "beneficiary-info": "cBB",
  "currency": "cBE",
  "fees-tooltip": "cBd"
};
