export default {
  "summary-description": "cEx",
  "detail": "cEw",
  "detail-value": "cEN",
  "account-icon": "cEv",
  "color-secondary": "cEo",
  "beneficiary-info": "cEn",
  "currency": "cEi",
  "beneficiary-name": "cEA",
  "separator": "cEY",
  "instant-subtitle": "cEh",
  "instant-wrapper": "cEf",
  "instant-header": "cEK",
  "instant-toggle": "cEG",
  "instant--disabled": "cEr",
  "limit-exceeded-disclaimer": "cEb",
  "sticky-panel": "cEy",
  "total-interest": "cEU",
  "tooltip-icon": "cEj",
  "tooltip": "cEW",
  "payment-options": "cEu"
};
