/* import __COLOCATED_TEMPLATE__ from './sdi-status-box.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { Tag } from '@repo/design-system-kit';

import { EINVOICING_STATUS, EINVOICING_STATUS_COLORS } from 'qonto/constants/receivable-invoice';

export default class ReceivableInvoicesEInvoicingStatusComponent extends Component {
  tag = Tag;

  @service intl;

  _einvoicingStatusTranslations = {
    [EINVOICING_STATUS.APPROVED]: this.intl.t(
      'receivable-invoices.invoice-modal.sdi.approved.status'
    ),
    [EINVOICING_STATUS.NOT_DELIVERED]: this.intl.t(
      'receivable-invoices.invoice-modal.sdi.pending-delivery.status'
    ),
    [EINVOICING_STATUS.PENDING]: this.intl.t(
      'receivable-invoices.invoice-modal.sdi.pending.status'
    ),
    [EINVOICING_STATUS.REJECTED]: this.intl.t(
      'receivable-invoices.invoice-modal.sdi.declined.status'
    ),
    [EINVOICING_STATUS.SUBMITTED]: this.intl.t(
      'receivable-invoices.invoice-modal.sdi.submitted.status'
    ),
    [EINVOICING_STATUS.SUBMISSION_FAILED]: this.intl.t(
      'receivable-invoices.invoice-modal.sdi.submission-failed.status'
    ),
  };

  _creditNoteStatusTranslations = {
    [EINVOICING_STATUS.APPROVED]: this.intl.t(
      'receivable-invoices.credit-note-modal.sdi.approved.status'
    ),
    [EINVOICING_STATUS.NOT_DELIVERED]: this.intl.t(
      'receivable-invoices.credit-note-modal.sdi.pending-delivery.status'
    ),
    [EINVOICING_STATUS.PENDING]: this.intl.t(
      'receivable-invoices.invoice-modal.sdi.pending.status'
    ),
    [EINVOICING_STATUS.REJECTED]: this.intl.t(
      'receivable-invoices.credit-note-modal.sdi.declined.status'
    ),
    [EINVOICING_STATUS.SUBMITTED]: this.intl.t(
      'receivable-invoices.credit-note-modal.sdi.submitted.status'
    ),
    [EINVOICING_STATUS.SUBMISSION_FAILED]: this.intl.t(
      'receivable-invoices.credit-note-modal.sdi.submission-failed.status'
    ),
  };

  _einvoicingStatusDisclaimerTranslations = {
    [EINVOICING_STATUS.APPROVED]: this.intl.t(
      'receivable-invoices.invoice-modal.sdi.approved.disclaimer'
    ),
    [EINVOICING_STATUS.NOT_DELIVERED]: this.intl.t(
      'receivable-invoices.invoice-modal.sdi.pending-delivery.disclaimer'
    ),
    [EINVOICING_STATUS.PENDING]: this.intl.t(
      'receivable-invoices.invoice-modal.sdi.pending.disclaimer'
    ),
    [EINVOICING_STATUS.REJECTED]: this.intl.t(
      'receivable-invoices.invoice-modal.sdi.declined.disclaimer'
    ),
    [EINVOICING_STATUS.SUBMITTED]: this.intl.t(
      'receivable-invoices.invoice-modal.sdi.submitted.disclaimer'
    ),
    [EINVOICING_STATUS.SUBMISSION_FAILED]: this.intl.t(
      'receivable-invoices.invoice-modal.sdi.submission-failed.disclaimer'
    ),
  };

  _einvoicingCreditNoteStatusDisclaimerTranslations = {
    [EINVOICING_STATUS.APPROVED]: this.intl.t(
      'receivable-invoices.credit-note-modal.sdi.approved.disclaimer'
    ),
    [EINVOICING_STATUS.NOT_DELIVERED]: this.intl.t(
      'receivable-invoices.credit-note-modal.sdi.pending-delivery.disclaimer'
    ),
    [EINVOICING_STATUS.PENDING]: this.intl.t(
      'receivable-invoices.credit-note-modal.sdi.pending.disclaimer'
    ),
    [EINVOICING_STATUS.REJECTED]: this.intl.t(
      'receivable-invoices.credit-note-modal.sdi.declined.disclaimer'
    ),
    [EINVOICING_STATUS.SUBMITTED]: this.intl.t(
      'receivable-invoices.credit-note-modal.sdi.submitted.disclaimer'
    ),
    [EINVOICING_STATUS.SUBMISSION_FAILED]: this.intl.t(
      'receivable-invoices.credit-note-modal.sdi.submission-failed.disclaimer'
    ),
  };

  _einvoicingSelfBilledInvoiceDisclaimerTranslations = {
    [EINVOICING_STATUS.APPROVED]: this.intl.t('self-billing.view-modal.sdi.approved.disclaimer'),
    [EINVOICING_STATUS.NOT_DELIVERED]: this.intl.t(
      'self-billing.view-modal.sdi.pending.disclaimer'
    ),
    [EINVOICING_STATUS.PENDING]: this.intl.t('self-billing.view-modal.sdi.pending.disclaimer'),
    [EINVOICING_STATUS.REJECTED]: this.intl.t('self-billing.view-modal.sdi.declined.disclaimer'),
    [EINVOICING_STATUS.SUBMITTED]: this.intl.t('self-billing.view-modal.sdi.submitted.disclaimer'),
    [EINVOICING_STATUS.SUBMISSION_FAILED]: this.intl.t(
      'self-billing.view-modal.sdi.submission-failed.disclaimer'
    ),
  };

  get eStatus() {
    let { einvoicingStatus } = this.args;

    return {
      text: this.einvoicingStatus,
      color: EINVOICING_STATUS_COLORS[einvoicingStatus],
    };
  }

  get einvoicingStatus() {
    let { isCreditNote, einvoicingStatus } = this.args;

    if (isCreditNote) {
      return this._creditNoteStatusTranslations[einvoicingStatus];
    }

    return this._einvoicingStatusTranslations[einvoicingStatus];
  }

  get einvoicingStatusDisclaimer() {
    if (this.args.isSelfBillingInvoice)
      return this._einvoicingSelfBilledInvoiceDisclaimerTranslations[this.args.einvoicingStatus];

    if (this.args.isCreditNote)
      return this._einvoicingCreditNoteStatusDisclaimerTranslations[this.args.einvoicingStatus];

    return this._einvoicingStatusDisclaimerTranslations[this.args.einvoicingStatus];
  }

  get einvoicingStatusLevel() {
    return {
      [EINVOICING_STATUS.APPROVED]: 'info',
      [EINVOICING_STATUS.NOT_DELIVERED]: 'error',
      [EINVOICING_STATUS.PENDING]: 'warning',
      [EINVOICING_STATUS.REJECTED]: 'error',
      [EINVOICING_STATUS.SUBMITTED]: 'warning',
      [EINVOICING_STATUS.SUBMISSION_FAILED]: 'error',
    }[this.args.einvoicingStatus];
  }

  get einvoicingStatusDisplay() {
    return Object.values(EINVOICING_STATUS).includes(this.args.einvoicingStatus);
  }
}
