export default {
  "step-container": "jf",
  "content": "jK",
  "detail": "jG",
  "secondary": "jr",
  "reference": "jb",
  "fees-label": "jy",
  "fees-tooltip": "jU",
  "separator": "jj",
  "mt-32": "jW"
};
