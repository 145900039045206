export default {
  "invite-review": "ccV",
  "invite-review-info": "ccL",
  "invite-review-info--invitee": "ccT",
  "disclaimer-button": "ccz",
  "info-block": "cca",
  "ev": "ccH",
  "tooltip": "ccO",
  "description": "ccm"
};
