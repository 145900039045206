export default {
  "mandate-new-no-mandate": "uT",
  "left-side": "uz",
  "left-side__wrapper": "ua",
  "left-side__wrapper-title": "uH",
  "left-side__wrapper-details": "uO",
  "left-side__wrapper-details-item": "um",
  "right-side": "up",
  "multi-accounts": "ux",
  "right-side__wrapper": "uw",
  "right-side__wrapper-name": "uN",
  "right-side__wrapper-address": "uv",
  "right-side__wrapper-iban-dashed": "uo",
  "right-side__wrapper-address-label": "un",
  "right-side__wrapper-iban-label": "ui",
  "right-side__wrapper-bic-label": "uA",
  "right-side__wrapper-address-dashed": "uY",
  "right-side__wrapper-bic-dashed": "uh",
  "dropdown-icon": "uf",
  "dropdown": "uK",
  "multi-accounts-illustration": "uG"
};
