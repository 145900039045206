export default {
  "container": "cQJ",
  "has-background": "cQs",
  "wrapper": "cQF",
  "top-disclaimer": "cQg",
  "error-wrapper": "cQD",
  "bottom-btns": "cQV",
  "bottom-wrapper": "cQL",
  "tab-nav": "cQT",
  "tab-nav-small": "cQz",
  "btn-clear": "cQa",
  "error-illustration": "cQH",
  "error-title": "cQO title-1",
  "disclaimer-wrapper": "cQm",
  "disclaimer": "cQp",
  "error-description": "cQx body-2",
  "table-wrapper": "cQw",
  "title": "cQN title-1",
  "lineup-toggle": "cQv",
  "description": "cQo body-2",
  "loading-wrapper": "cQn",
  "cards-wrapper": "cQi",
  "card": "cQA",
  "plan": "cQY",
  "placeholder-header": "cQh",
  "disclaimer-info": "cQf",
  "spinner": "cQK"
};
