export default {
  "invitations-password-form": "cCt",
  "mobile-mode": "cCM",
  "logo": "cCP",
  "header": "cCl",
  "controls": "cCX",
  "footer": "cCC",
  "submit-cta": "cCk",
  "subtitle": "cCJ"
};
